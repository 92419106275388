<script>

/*
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
*/
  
  import useVuelidate from "@vuelidate/core";

  import Layout from "../../../layouts/main.vue";
  import appConfig from "../../../../app.config";
  import PageHeader from "@/components/page-header";

  import serverConfig   from '@/helpers/config';

  import Swal from "sweetalert2";
  import axios from 'axios';
  import moment from 'moment';

  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { mapActions, mapGetters} from "vuex";

  export default {
    page: {
      title: "Gestione Seriali prodotto",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    setup() {
      moment.locale('it')
      const store = useStore();
 
      let tk = 'Bearer '+localStorage.getItem('tk')
      let brands = computed(() => store.getters["ecommercesettings/getBrands"])


      return {
        v$: useVuelidate(),
        tk,
        brands
      };
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        user:this.$store.state.auth,
        title: "Gestione seriali prodotto",
        min_date: moment().toISOString(),
        item:{
/**
 *      { "low_availability": { "choice": "NO" }, 
 *      "on_demand": { "choice": "NO" }, 
 *      "position": 0, 
 *      "giacenzaEffettiva": 0, 
 *      "size": "", "colors": [], 
 *      "status": "init", 
 *      "packagingLength": 0, 
 *       "packagingWidth": 0, 
 *       "packagingHeight": 0, "packagingWeight": 0, "packagingThickness": 0, "tokenizer": [], 
 *       "_id": "64abe23d0eb6500ba5518a22", 
 *       "Listino": "01", 
 *       "Famiglia": "Split", 
 *       "Tipologia": "Componenti", 
 *        "Refrigerante": "R32", 
 *       "GruppoSconto": "Residenziale", 
 *       "gcode": "FTXA20AW", 
 *         "ean": "4548848657117", 
 *       "CodMerc": "84159000", 
 *       "Descrizione": "Stylish R32 interna white 20", 
 *       "FamECR": "", 
 *       "Parametro": "", "ECR": "", 
 *        "Febbraio 2023": "510.02", 
 *        "Stato": "", "Lordo": "13.50", " Netto": "11.50", 
 *         "um": "KG", "Lunghezza": 0.88, "Larghezza": 0.4, "Altezza": 0.3, "Unità": "M", "Volume": 0.11, "UV": "M3", "Volume_2": 106, "UV_2": "DM3", "TipoApparecchiature": "", "TipologiaInst": "", "Circuiti": "", "TipologiaFGAS": "", "QtaKg": "", "createdDate": "2023-07-10T13:52:08.116Z", "id": "64abe23d0eb6500ba5518a22" }
 */    
          um:'',
          Famiglia:'',
          Tipologia:'',
          Refrigerante:'',
          GruppoSconto:'',
          gcode:'',
          ean:'',
          CodMerc:'',
          Nome:'',
          slug:'',
          Descrizione:'',
          FamECR:'',
          Parametro:'',
          ECR:'',
          Prezzo:0.00,
          Lunghezza:0.00,
          Larghezza:0.00,
          Altezza:0.00,
          Unita:'',
          Volume:0.00,
          UV:'',
          Volume_2:0.00,
          UV_2:'',
          TipoApparecchiature:'',
          TipologiaInst:'',
          Circuiti:'',
          TipologiaFGAS:'',
          QtaKg:0.00,
          CodFamiglia: '',
          CodMarca: '',
          CodIva:22,
          publishSchedule: moment.unix(),
          publishScheduleISO: moment().toISOString(),
        },
        retailPrice:false,
        items: [
          {
            text: "Magazzino",
            href: "/warehouse/products",
          },
          {
            text: "Prodotto",
            href: `/warehouse/edit-product/${this.$route.params.id}`,
          },
          {
            text: "Seriali prodotto",
            active: true,
          },
        ],
        page: 1,
        perPage: 10,
        sortRes:'asc',
        value: 'All',
        n_results:0,
        n_ordersResults:0,
        pages: [],
        responseList: [
        ],
        paginated:{},
        pageOrders: 1,
        perPageOrders: 10,
        responseOrdersList: [
        ],
        serialCode:'',
        position:'',
      };
    },
    components: {
      Layout,
      PageHeader,
    },
    computed:{
      ...mapGetters('ecommercesettings', ['getBrands']),
    },
    mounted() {

      if (this.$route.query.dbgr && this.$route.query.filterBy && this.$route.query.paginateSettings&&this.$route.query.syncroESAction&&this.$route.query.value ){
        this.items = [
          {
            text: "Magazzino",
            href: `/warehouse/products?dbgr=${this.$route.query.dbgr}&filterBy=${this.$route.query.filterBy}&paginateSettings=${this.$route.query.paginateSettings}&syncroESAction=${this.$route.query.syncroESAction}&value=${this.$route.query.value}`,
          },
          {
            text: "Prodotto",
            href: `/warehouse/edit-product/${this.$route.params.id}`,
          },
          {
            text: "Seriali prodotto",
            active: true,
          },
        ]
      }

      this.getItem()
    },
    methods: {
      ...mapActions('ecommercesettings', ['getBrands']),
      axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              console.log('IMP')
              console.log(error)
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
      },
      httpErrorHandler(error) {
  if (error === null) throw new Error('Unrecoverable error!! Error is null!')
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error?.response
    const request = error?.request
   // const config = error?.config //here we have access the config used to make the api call (we can make a retry using this conf)

    if (error.code === 'ERR_NETWORK') {
      console.log('connection problems..')
    } else if (error.code === 'ERR_CANCELED') {
      console.log('connection canceled..')
    }
    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response?.status
      if (statusCode === 404) {
        console.log('The requested resource does not exist or has been deleted')
      } else if (statusCode === 401) {
        console.log('Please login to access this resource')
        //redirect user to login
      }
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
    }
  }
  //Something happened in setting up the request and triggered an Error
  console.log(error.message)
},
      getParent(){
        this.getItem()
      },
      Capitalize(key){
        this.item[key].toUpperCase()
      },
      convertDate(date) {
        return moment(date).format('DD/MM/YYYY HH:MM:SS')
      },
      getItem() {
        this.data = {}
          axios.get(`${this.UrlServer}products/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
            if (response.data && response.data.length > 0) {
              this.item = response.data[0]
              if (!response.data[0].Nome){
                this.item.Nome = response.data[0].Descrizione.slice(0,50)
              }
              this.serials(this.$route.params.id,this.item.CodArt)
            }                  
        })
      },
      paginate(responseList) {
          let page = this.page;
          let perPage = this.perPage;
          let from = page * perPage - perPage;
          let to = page * perPage;
          return responseList.slice(from, to);
        },
      paginateAct(pg){
          this.page = pg
          //this.getList()
          this.serials()
        },
      paginatedOrders(response) {
          let page = this.pageOrders;
          let perPage = this.perPageOrders;
          let from = page * perPage - perPage;
          let to = page * perPage;
          return response.slice(from, to);
        },
      checkAndSyncro(){
       
        axios.get(`http://localhost:57138/scandir/`, {headers: { authorization:this.tk}}).then((response) => {
            if (response) {
              console.log('LOCALHOST')
              console.log(response)
             // this.serials(this.$route.params.id,this.item.CodArt)
            }                  
        })
        
        .catch( (e)=>{

          this.httpErrorHandler(e)
        })
          
      },
      serials(idProd,code){
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}products/serials/code/product`, 
          { 
            id: idProd,
            codArt: code,
            status: 'processing',
            perPage: this.perPage,
            page: this.page,
            
          }, 
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
         
            if (response.data) {
                this.responseOrdersList = response.data.results.data
                this.paginated =  this.responseOrdersList
            }
        
        })
      },
      createSerial(){
        if (this.serialCode.length>8) {
          this.axiosInterceptor()
          axios.post(`${this.UrlServer}products/create/serial/code/product`, 
            { 
              id: this.item._id,
              codArt: this.item.CodArt,
              DBGruppo: this.item.DBGruppo,
              serialCode:this.serialCode,
              position:this.position,
            }, 
            { 
              headers: { 'authorization':this.tk}
            }
            ).then(response=>{
              if (response.data) {
                  this.getItem()
              }
            })
        }

      },
    updateItem(id,pos) {
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}products/serial/code/product/${id}`,{position:pos}, {headers: { authorization:this.tk}}).then((response) => {
       if (response) {
        this.getItem()      
       }
               
      })
    },
    updateSerial(id,pos){
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}products/serial/code/product/${id}`, {position:pos}, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
            } )
            .then((response)=>{
              if (response.data) {
                document.getElementById(`serial-${id}`).style.color = "green"
              }
          })
      },
    deletedata(id){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}products/serial/${id}`, {headers: { authorization:this.tk}} )
                .then(()=>{
                  self.getItem() 
              })
            }
        });
      },

    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12 col-md-12">
        <form>
          <div class="card">
            <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1" >
              Scheda prodotto: {{ item.Nome }}
            </h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                Codice:<strong>{{ item.CodArt }}</strong>
              <div class="pr-5">
                Magazzino: {{ item.DBGruppo }}
              </div>
              </div>
            </div>
          </div>
            <div class="card-body">
              <div class="row mb-25">
                <div class="col-lg-8 col-md-12"></div>
                <div class="col-lg-4 col-md-12">
                  <div style="float: right;">
                    <button type="button" class="btn btn-primary" >
                      <a href="javascript:void(0)" class="text-white" @click="checkAndSyncro">
                        <i class="ri ri-rss-line"></i>  
                        Controlla e Sincronizza
                      </a>
                    </button>
                    &nbsp;
                    <button type="button" class="btn btn-info" data-bs-toggle="modal" >
                      <a :href="`${UrlServer}docugen/download/product/serials/${item._id}`" class="text-white">
                        <i class="ri-file-word-2-line"></i>  
                        Esporta Seriali
                      </a>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row back-grey">

                <div class="col-lg-3 col-md-12">
                  <div class="mb-3" >
                    <div >
                      <label class="form-label" for="product-gcode-input">Codice seriale</label>
                        <div class="input-group mb-3">
                          <span class="input-group-text" id="product-gcode-addon">S</span>
                            <input type="text" class="form-control" id="product-serialCode-input" placeholder="Inserisci codice seriale"
                                  aria-label="serialCode" aria-describedby="product-serialCode-addon" v-model="serialCode" />
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="mb-3">
                    <div class="input-group mb-3 pt-25">

                      <input type="text" class="form-control" id="product-position-input" placeholder="Posizione (opzionale)"
                      aria-label="position" aria-describedby="product-position-addon" v-model="position" />
                  </div>
                  </div>
                </div>  
                <div class="col-lg-3 col-md-12">
                  <div class="mb-3">
                    <div class="input-group mb-3 pt-25">
                    <button class="btn btn-info " type="button" @click="createSerial()">
                      <i class="mdi mdi-view-list-outline"></i> Associa seriale
                    </button>
                  </div>
                  </div>
                </div>  
                <div class="col-lg-6 col-md-12 mb-20">
                </div>                    
            </div>
            <div class="card-body">
              <div class="row back-grey">
                <div class="col-lg-12 col-md-12 ">
                  <div class="card ">
        <div class="back-grey-0">
        </div>
        <div class="card-header back-info-light">
            <h5 class="card-title mb-0 bold-white"> <i class="mdi mdi-view-list-outline"></i> Numeri seriali associati</h5>
        </div>
        <div class="card-body">
          <div class="card-header ">
            <div class="row align-items-center">
              <div class="col mb-5">
                <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">

                  <li class="nav-item">
                    <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                       Lista
                      <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_ordersResults}}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-auto mt-5">

              </div>
              <div class="col-auto mt-5">
               
               
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-card mt-5">
              <div class="row thRow  ">
               
                <div class="col text-left">
                  Codice
                </div>
                <div class="col text-left">
                 Posizione
                </div>
                <div class="col text-left">
                 Registrazione
                </div>
                <div class="col text-left">
                 Azione
                </div>
              </div>
              
              <div class="row list tRow form-check-all d-flex justify-content-end " v-for=" (item, index) of responseOrdersList" :key="index" >
              
                <div class="col text-left" >
                  <strong>{{ item.serialCode}}</strong>
                  
                </div>
                <div class="col text-left" >
                 
                  <input type="text" class="form-control" :id="`serial-${item._id}`" v-model="item.position" @blur="updateSerial(item._id,item.position)" />

                </div>
                <div class="col text-left" >
                  <strong>{{ convertDate(item.createdDate) }}</strong>
                 
                </div>
                
                <div class="col" >
                  <ul class="list-inline ">
                    <li class="list-inline-item edit pr-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi seriale">
                      <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="deletedata(item._id)">
                        <i class="ri-delete-bin-6-line fs-16 text-red"></i>
                      </a>
                    </li>      
                  </ul>
                </div>
              </div>
              
            </div>
                <div>
                  <div class="table-responsive table-card mb-1">

                    <div class="noresult" style="display: none">
                      <div class="text-center">
                        <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                          :height="75" :width="75" />
                        <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                        <p class="text-muted mb-0">
                        
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex tRow justify-content-end mt-3" >
                        <div class="col-lg-2 col-md-12 text-right">
                            <label class="pt-2">Risultati per pagina:</label>
                        </div>        
                        <div class="col-lg-1 col-md-12 ">
                            <div class="pl-10 pr-20">
                                <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                    <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 ">
                            <div class="pagination-wrap hstack gap-2">
                            <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                                Precedenti
                            </a>
                            <ul class="pagination listjs-pagination mb-5 pr-10 pl-10" >
                                <li :class="{
                                        active: index == page,
                                        disabled: index == '...',
                                        }" v-for="index in paginated.total_pages" :key="index">
                                    <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                                </li>
                            
                            </ul>
                            <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                            Successivi
                            </a>
                            </div>
                        </div>
                  </div>
            </div>
      </div>
    </div>
  </div>
                </div>
              </div>
            </div>

            <div class="row  mt-10 mb-10"></div>
            </div>

          </div>
          
          <!-- end card -->

          <div class="text-end mb-3">
            
          </div>
        </form>
      </div>
      <!-- end col -->
    </div>
    
  </Layout>
</template>